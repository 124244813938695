const protocol = window.location.protocol
const host = window.location.origin.split(protocol)[1]
/* 应用常量配置 */
export const NAVBAR_MENUS = [
  {
    name: 'sysManagement', // 权限模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_sysManagement_URL}`
      : setSubApphost(process.env.VUE_APP_sysManagement_URL),
    activeRule: 'sysManagement'
  },
  // {
  //   name: 'protolTestTool',
  //   entry: isPrdEnv()
  //     ? `${host + process.env.VUE_APP_testTool_URL}`
  //     : process.env.VUE_APP_testTool_URL,
  //   activeRule: 'protolTestTool',
  // },
  // {
  //   name: 'dataAudit',
  //   entry: isPrdEnv()
  //     ? `${host + process.env.VUE_APP_WORKFLOW_URL}`
  //     : process.env.VUE_APP_WORKFLOW_URL,
  //   activeRule: 'dataAudit',
  // },
  {
    name: 'rtm', // 远传模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_rtm_URL}`
      : setSubApphost(process.env.VUE_APP_rtm_URL),
    activeRule: 'rtm'
  },
  {
    name: 'patrolManagement', // 巡检管理模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_patrolManagement_URL}`
      : setSubApphost(process.env.VUE_APP_patrolManagement_URL),
    activeRule: 'patrolManagement'
  },
  {
    name: 'leakDetectionManage', // 检漏管理模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_leakDetectionManage_URL}`
      : setSubApphost(process.env.VUE_APP_leakDetectionManage_URL),
    activeRule: 'leakDetectionManage'
  },
  {
    name: 'outWorkCenter', // 外业中心模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_outWorkCenter_URL}`
      : setSubApphost(process.env.VUE_APP_outWorkCenter_URL),
    activeRule: 'outWorkCenter'
  },
  {
    name: 'workflowCenter', // 工单中心模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_workflow_URL}`
      : setSubApphost(process.env.VUE_APP_workflow_URL),
    activeRule: 'workflowCenter'
  },
  {
    name: 'eventsCenter', // 事件中心模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_eventsCenter_URL}`
      : setSubApphost(process.env.VUE_APP_eventsCenter_URL),
    activeRule: 'eventsCenter'
  },
  {
    name: 'maintenance', // 维修中心模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_maintenance_URL}`
      : setSubApphost(process.env.VUE_APP_maintenance_URL),
    activeRule: 'maintenance'
  },
  {
    name: 'messageCenter', // 消息中心模块
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_messageCenter_URL}`
      : setSubApphost(process.env.VUE_APP_messageCenter_URL),
    activeRule: 'messageCenter'
  },
  {
    name: 'basicService', // 基础服务
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_basicService_URL}`
      : setSubApphost(process.env.VUE_APP_basicService_URL),
    activeRule: 'basicService'
  },
  {
    name: 'dataAudit', // 数据源
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_dataAudit_URL}`
      : setSubApphost(process.env.VUE_APP_dataAudit_URL),
    activeRule: 'dataAudit'
  },
  {
    name: 'IntegratedOperation', // 综合管理
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_IntegratedOperation_URL}`
      : setSubApphost(process.env.VUE_APP_IntegratedOperation_URL),
    activeRule: 'IntegratedOperation'
  },
  {
    name: 'gateway', // 门户
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_gateway_URL}`
      : setSubApphost(process.env.VUE_APP_gateway_URL),
    activeRule: 'gateway'
  },
  {
    name: 'largeScreen', // 大屏
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_largeScreen_URL}`
      : setSubApphost(process.env.VUE_APP_largeScreen_URL),
    activeRule: 'largeScreen'
  },
  {
    name: 'engineering', // 工程管理
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_engineering_URL}`
      : setSubApphost(process.env.VUE_APP_engineering_URL),
    activeRule: 'engineering'
  },
  {
    name: 'collectAndEdit', // 采编
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_collectAndEdit_URL}`
      : setSubApphost(process.env.VUE_APP_collectAndEdit_URL),
    activeRule: 'collectAndEdit'
  },
  {
    name: 'dma', // dma
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_dma_URL}`
      : setSubApphost(process.env.VUE_APP_dma_URL),
    activeRule: 'dma'
  },
  // {
  //   name: 'esbSystem', // esb,目前esb没有接入基座，是转跳过去的
  //   entry: 'none',
  //   activeRule: 'esbSystem'
  // },
  {
    name: 'allOverview', // dma-远传总览
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_allOverview_URL}`
      : setSubApphost(process.env.VUE_APP_allOverview_URL),
    activeRule: 'allOverview'
  },
  {
    name: 'esbSystem', // esb
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_esb_URL}`
      : setSubApphost(process.env.VUE_APP_esb_URL),
    activeRule: 'esbSystem'
  },
  {
    name: 'noice', // noice 噪声记录
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_noice_URL}`
      : setSubApphost(process.env.VUE_APP_noice_URL),
    activeRule: 'noice'
  },
  {
    name: 'billing', // 新余开账
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_billing_URL}`
      : setSubApphost(process.env.VUE_APP_billing_URL),
    activeRule: 'billing'
  },
  {
    name: 'rqa', // 抄表质量评估
    entry: isPrdEnv()
      ? `${host + process.env.VUE_APP_rqa_URL}`
      : setSubApphost(process.env.VUE_APP_rqa_URL),
    activeRule: 'rqa'
  }
]

function setSubApphost(subAppHost) {
  // 兼容ip地址访问形式，既提供ip地址给外部访问时，子应用将根据当前host进行注册入口地址
  if (subAppHost?.startsWith('//') && !host.includes('localhost')) {
    subAppHost = subAppHost.replace('//', 'http://')
    const subAppPart = new URL(subAppHost).port
    // console.log(`${window.location.hostname}:${subAppPart}`)
    return `${window.location.hostname}:${subAppPart}`
  }
  return subAppHost
}

function isPrdEnv() {
  return process.env.VUE_APP_BUILD_ENV === 'prd' || process.env.VUE_APP_BUILD_ENV === 'sgl'
}
